import React from "react";
import styled from "styled-components";
import pic from "../img/to.png";
import { useNavigate } from "react-router-dom";

const LandingScreen = () => {
	const navigate = useNavigate();
	const [name, setName] = React.useState("");

	const AddUser = () => {
		window.localStorage.setItem("codelabname", JSON.stringify(name));

		navigate("/todolist");
	};

	return (
		<Container>
			<ImageBox>
				<img src={pic} />
			</ImageBox>
			<h1>Welcome to CodeLab Todo </h1>
			<p>Please flll in your name to start creating your task forn the day</p>
			<br />
			<InputField
				onChange={(e) => {
					setName(e.target.value);
				}}
				placeholder='Enter your FullName'
			/>
			{name !== "" ? (
				<Button onClick={AddUser} bg='#3787EB'>
					Let's Go
				</Button>
			) : (
				<Button bg='silver'>Let's Start</Button>
			)}

			<span>Developed by CodeLab</span>
		</Container>
	);
};

export default LandingScreen;

const ImageBox = styled.div`
	height: 200px;
	width: 300px;
	/* background-color: red; */
	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
`;

const Button = styled.button<{ bg: string }>`
	height: 50px;
	width: 400px;
	margin-top: 20px;
	border: none;
	outline: none;
	background-color: ${(props) => props.bg};
	color: white;
	border-radius: 5px;
	transition: all 350ms;
	cursor: pointer;
	font-size: 16px;

	:hover {
		transform: scale(0.96);
	}
`;

const InputField = styled.input`
	height: 50px;
	width: 400px;
	border: none;
	padding-left: 10px;
	border-radius: 5px;
	outline-color: #dcd9f8;
	transition: all 350ms;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	flex-direction: column;
	background: linear-gradient(0deg, rgba(255, 255, 255) 34%, rgba(55, 135, 235) 100%);

	h1 {
		margin: 0;
		color: black;
	}

	span {
		color: white;
		margin-top: 10px;
	}
	p {
		color: black;
	}
`;
