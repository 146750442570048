import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import good from "../img/goods.png"
import empt from "../img/empt.png"
import cancle from "../img/cancle.png"
import {AiFillEdit} from "react-icons/ai"
import { isDataView } from "util/types";
interface data {
  todo: string;
  id: number;
  status: boolean;
  Describe: string;
  start: string;
  end: string;
  time: string;
}
const TodoList = () => {
	const [todoValue, setTodoValue] = React.useState("");
	const [inputValue, setInputValue] = React.useState("")
	const [data, setData] = React.useState<data[]>([]);
	const [isEditing, setIsEditing] = useState(false);
  const [currentTodo, setCurrentTodo] = useState({});
  const [show, setShow] = useState<boolean>(false)
const [notshow, setNotShow] = useState<boolean>(false)

const Not = () => {
	setNotShow(!notshow)
}

  const Shows = () => {
	setShow(!show)
  }


   let idData: number = data.length + 1;

  //   creating task
  const addNewTask = () => {
    // sorting algoritm
    const sortinfo = (x: any) => {
      return (a: any, b: any) => {
        if (a[x] < b[x]) {
          return a[x];
        } else if (a[x] > b[x]) {
          return -1;
        }
        return 0;
      };
    };

    // time
    const now = new Date();

    setDate(now.toDateString());
    let hr = now.getHours().toString();
    let min = now.getMinutes().toString();
    let sec = now.getSeconds().toString();
    // let aporpm = now.toString();
    var aMorPm = hr >= "12" ? "pm" : "am";


    // get all data
    setData((prev) =>
      [
        ...prev,
        {
          status: false,
          todo: todoValue,
          id: idData,
          Describe: des,
          start: start,
          end: end,
          time: `${hr}:${min}:${sec}:${aMorPm}`,
        },
      ].sort(sortinfo("id"))
    );
  };

  // start and end
  const [start, setStart] = React.useState("");
  const [end, setEnd] = React.useState("");

  // date
  const [date, setDate] = React.useState<any>();
 

  //   delete task;
  const deleteTask = (id: number) => {
    let deleteData = data.filter((e) => e.id !== id);
    setData(deleteData);
  };

  // desctiption
  const [des, setdes] = React.useState("");

  //   edit
  const [edit, setedit] = React.useState(-1);
  // boolean operator
  const [falses, setfalses] = React.useState(false);
  // switch edit and update button
  const chageButton = () => {
    setfalses(!falses);
  };

  // capture edit input
  const [input, setinput] = React.useState("");

  const updateButton = () => {
    setfalses(!true);
    data[0].todo = input;
  };

  const editdata = (id: number) => {
    setedit(id);
  };

  // done
  const [done, setDone] = React.useState<any>(false);
  const changeDoneState = () => {
    setDone(true);
	setShow(true);
    data[0].status = true;
    // setShow(true)
  };


	

	return (
	<Container>
			<br />
			<br />
			<br />
			<br />
			<br />
			<h1>Welcome User </h1>
			<p>Let's create the task for today</p>
			<br />
		<InputField
			onChange={(e) => {
				setTodoValue(e.target.value);
			}}
				placeholder='Enter your task'
			/>

		<DateTimeHold>
				<DateTime>
				<h3>Time Limit</h3>
			</DateTime>
			<Inputs
			 onChange={(e) => {
				setStart(e.target.value)
			 }}
			 type="date"/>
			<TimeHold>
				<Starttimehold>
 						<Text><h3>Start Time</h3></Text>
 						<Time 
						onChange={(e) => {
							setStart(e.target.value)
						}}
						type="time"/>
 					</Starttimehold>
 					<Starttimehold>
						<Text><h3>End Time</h3></Text>
						<Time 
						onChange={(e) => {
						setEnd(e.target.value)
						}}
						type="time"/>
					</Starttimehold>
				</TimeHold>
				<Description>
 					<h3>Description</h3>
					{todoValue !== "" ? (
						<Textarea 
					onChange={(e) => {
						setdes(e.target.value)
					}} 
					placeholder="Description"
					/>
					) : null}
 				</Description>
			</DateTimeHold>

			{des !== "" ? (
				<Button onClick={addNewTask} bg='white'>
				Submit
 				</Button>
 			) : (
				<Button bg='silver'>Submit</Button>
			)}

		<br />
 			<br />
 			<br />
			<h3>All Task</h3>
			<CardHold>
				{data.map((data) => (
 					<Card key={data.id} >
 						{data.id === edit ? (
 							<input defaultValue={data.todo} />
						) : (
 							<Title>{data.todo}</Title>
 						)}

						<Dis>
							{data.Describe}
						</Dis>


						
							<Check type="checkbox"/>
						
						<ButHold>
							<button
							
								onClick={() => {
									chageButton();
									editdata(data.id)
								}}>
 								Edit
						</button>
							<button 
							onClick={() => {
								changeDoneState();
								setDone(data.id);
								
							}}>
								Done
							</button>
							<button
								onClick={() => {
									deleteTask(data.id);
								}}>
								Delete 							</button>
							<Down>
 								<Dates>{date}</Dates>
 								<Times>
 									<Start>
 										Start: {data.start}
 									</Start>
 									<End>End: {data.end}</End>
 								</Times>
 							</Down>
 						</ButHold>
 						{data.id === done  ? (
 							<Modal dn="flex">
 							<Image src={good}/>
 							<Texts>Congratulations</Texts>
 							<Task>Task Completed</Task>
							
 						</Modal>
 						) : (
							<Modal dn="">
 							<Image src={good}/>
 							<Texts>Congratulations</Texts>
 							<Task>Task Completed</Task>
							
 						</Modal>
						)}
					</Card>
				))}
			</CardHold>
	</Container>
	);
 };

export default TodoList;
const Cancle = styled.img`
	position: absolute;
	right: 20px;
	top: 20px;
	height: 20px;
`
const Check = styled.input`
	position: absolute;
	right: 10px;
	height: 20px;
	width: 20px;
`
const Task = styled.div`
	
`
const Texts = styled.div`
	margin-bottom: 15px;
	font-size: 25px;
`
const Image = styled.img`
	height: 50px;
	margin-bottom: 20px;
`
const Modal = styled.div<{ dn: string }>`
	width: 100%;
	height: 100%;
	background-color: white;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	display: ${({ dn }) => (dn ? "flex" : "none")};

	animation-name: grow-box;
	animation-duration: 0.4s;
	animation-timing-function: ease-in-out;

	@keyframes grow-box {
		0% {opacity: 0.2; }
		25% {opacity: 0.4; }
		50% {opacity: 0.6; }
		75% {opacity: 0.8; }
		100% {opacity: 0.9}
	}
`
const Dis = styled.div`
`
const Textarea = styled.textarea`
	width: 100%;
	padding-left: 10px;
	padding-top: 10px;
	height: 100px;
	border-radius: 10px;
	border: none;
	resize: none;
	outline: none;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`
const Description = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
`
const End = styled.div`
`
const Start = styled.div`
	
`
const Times = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-top: 15px;
`
const Dates = styled.div`
	
`
const Time = styled.input`
	height: 50px;
	width: 160px;
	padding-left: 10px;
	padding-right: 10px;
	border: none;
	outline: none;
	border-radius: 5px;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`
const Text = styled.div`
	h3{

	}
`
const Starttimehold = styled.div`
	display: flex;
	flex-direction: column;
`
const TimeHold = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`
const Inputs = styled.input`
	height: 50px;
	width: 380px;
	padding-left: 10px;
	padding-right: 10px;
	border: none;
	outline: none;
	border-radius: 5px;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`
const DateTime = styled.div`
	
`
const DateTimeHold = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`
const Icons = styled.div`

`
const P = styled.div`
	color: black;
`
const Down = styled.div`
	width: 93%;
	height: 30px;
	/* background-color: red; */
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 15px;
	flex-direction: column;
	
`
const Input = styled.input`
	height: 20px;
	width: 20px;
	background-color: green;
`

const CardHold = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	
`;
const Card = styled.div`
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	/* height: 200px; */
	width: 300px;
	background-color: white;
	display: flex;
	/* justify-content: center; */
	align-items: left;
	flex-direction: column;
	text-align: left;
	padding: 30px;
	border-radius: 5px;
	position: relative;
	margin: 10px;
	/* padding-bottom: 10px; */
`;
const Title = styled.div``;
const ButHold = styled.div`
	button {
		margin-right: 5px;
		height: 30px;
		width: 90px;
		margin-top: 20px;
		cursor: pointer;
	}
`;

const ImageBox = styled.div`
	height: 100px;
	width: 300px;
	/* background-color: red; */
	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
`;

const Button = styled.button<{ bg: string }>`
	height: 50px;
	width: 400px;
	margin-top: 20px;
	border: none;
	outline: none;
	background-color: ${(props) => props.bg};
	color: black;
	border-radius: 5px;
	transition: all 350ms;
	cursor: pointer;
	font-size: 16px;

	:hover {
		transform: scale(0.96);
	}
`;

const InputField = styled.input`
	height: 50px;
	width: 400px;
	border: none;
	padding-left: 10px;
	border-radius: 5px;
	outline-color: #dcd9f8;
	transition: all 350ms;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;


const Container = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	position: relative;
	background: #3787EB;
	padding-bottom: 30px;
	h1 {
		margin: 0;
		color: black;
	}
	h3{
		color: black;
	}
	p{
		color: black;
	}

	span {
		color: gray;
		margin-top: 10px;
	}
`;
