import React from 'react'
import AllRoutes from './Components/AllRoutes/AllRoutes'
import TodoList from './Components/TodoList'


const App = () => {
  return (
    <div>
      <AllRoutes />
    </div>
  )
}

export default App